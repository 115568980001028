'use client'
import { NavItem } from '@/components/common/NavItem'
import { DEFAULT_PAGE_URL } from '@/constants/page'
import { Button } from '@planda/design-system'
import { signIn } from 'next-auth/react'
import React from 'react'
import { css } from 'styled-system/css'
import SignUpButton from '../SignUpButton'

const signInButtonProps = {
    containerCSS: {
        // position: 'absolute',
        // right: leftPadding,
        backgroundColor: '$primary1',
        color: '$gray12',
    },
    onClick: () => signIn(undefined, { callbackUrl: DEFAULT_PAGE_URL + '?demo=false' }),
}

const RightButtons = () => {
    return (
        <div className={css({
            display: 'none',
            right: 'aboutLeftPadding',
            color: '$gray12',
            gap: 10,
            position: 'relative',
            '@bp1': {
                right: 'auto',
                display: 'flex'
            },
            '@bp2': {
                right: 'aboutLeftPadding',
                position: 'absolute',
            }
        })}>
            <NavItem
                href={'#'}
                onClick={() => signIn(undefined, { callbackUrl: DEFAULT_PAGE_URL + '?demo=false' })}
            >Log in</NavItem>
            <SignUpButton />
        </div>
    )
}

export default RightButtons