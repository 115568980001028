import Link from 'next/link'
import { styled } from 'styled-system/jsx'

export const NavItem = styled(Link, {
    base: {
        display: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        padding: '0.5rem 0.8rem',
        color: '$gray11',
        borderRadius: 10,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
        fontSize: 14, // or 12
        '&:hover': {
            backgroundColor: '$primary2',
            color: "$primary9"
        },
        '@bp1': {
            display: "flex"
        }
    },
    variants: {
        active: {
            true: {
                textDecoration: 'underline',
            }
        }
    },
})