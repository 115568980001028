'use client'
import { DEFAULT_PAGE_URL } from '@/constants/page'
import { Button } from '@planda/design-system'
import { RecipeVariantProps } from '@planda/styled-system/types'
import { signIn } from 'next-auth/react'
import React, { ComponentProps } from 'react'
import { cx, css, cva } from 'styled-system/css'
import { styled } from 'styled-system/jsx'
import { ButtonVariant } from 'styled-system/recipes'

const signUpButton = cva({
    base: {
        backgroundColor: '$primary9',
        color: '$loContrast',
        '&:hover': {
            cursor: 'grabbing',
        },
    },
    variants: {
        size: {
            small: {
                borderRadius: 10,
                fontSize: 14,
            },
            medium: {
                fontSize: 28,
                padding: '28px',
                borderRadius: 20,
            },
            giant: {
                fontSize: 32,
                padding: '35px',
                borderRadius: 20,
                // height: 100,
                // width: 300,
            }
        }
    },
    defaultVariants: {
        size: 'small',
    }
})

type VariantProps = RecipeVariantProps<typeof signUpButton>

const buttonStyles = {

}

const GetStartedButton = styled('button', {
    base: {
        borderRadius: 10,
        fontWeight: 500,
        fontFamily: 'system-ui,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        backgroundColor: '$primary9',
        color: '$loContrast',
        boxShadow: '$boxShadow',
        border: '2px solid {colors.$primary9}',
        '&:hover': {
            color: '$primary9',
            backgroundColor: '$primary2',
            transform: 'scale(1.01)',
        },
    },
    variants: {
        size: {
            small: {
                borderRadius: 10,
                fontSize: 14,
                padding: '0.5rem 0.8rem',
            },
            medium: {
                padding: '1rem 2rem',
                fontSize: '1.2rem',
                lineHeight: '2rem',
            },
            giant: {}
        }
    }
})
const SignUpButton = ({ variants, size = 'small', ...props }: { variants?: ButtonVariant } & VariantProps) => {


    return (
        <div className={css({
            display: 'flex',
            flexDirection: 'column',
        })}>
            {/* {size == 'small' && <button
                className={cx(button({
                    size: '2',
                    ...variants,
                }), signUpButton({
                    size,
                }))}
                onClick={() => signIn(undefined, { callbackUrl: DEFAULT_PAGE_URL + '?action=create-account&demo=false' })}
                {...props}
            >Sign up</button>} */}
            <GetStartedButton
                size={size}
                onClick={() => signIn(undefined, { callbackUrl: DEFAULT_PAGE_URL + '?action=create-account&demo=false' })}
                {...props} >Sign up</GetStartedButton>
            {size !== 'small' ? <span className={css({
                textAlign: 'center', color: '$unimportant',
                fontSize: 14,
                marginBlock: 8,
            })}>for free</span> : null}
        </div>
    )
}

export default SignUpButton