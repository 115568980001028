export const DEFAULT_PAGE_URL = '/home'

export const FEEDBACK_BUG_DOC =
    'https://docs.google.com/document/d/1MlsGE_4pGFpBLt6lzYnNL6unIfn_H4imXdM669DRfJc/edit'

export const WEEK_VIEWS = ['list', 'time'] as const

export enum WeekView {
    LIST = 'list',
    TIME = 'time',
}

export function weekViewIsValid(view: string) {
    return WEEK_VIEWS.includes(view as WeekView)
}
